// Primary colors
$primaryDarkBlue: #101d34;
$primaryNightBlue: #214571;
$primaryBlue: #0060f1;
$primaryRed: #cb0102;
$primaryGreen: #00c971;

$neuturalGray: #808884;
$neuturalSilver: #e7e9eb;

$gradientSkyBlue: linear-gradient(220deg, #68cae4 0%, #3d9aff 100%) 0% 0% no-repeat
  padding-box;
$gradientSilver: linear-gradient(180deg, #fdfdfd 0%, #e7e9eb 100%) 0% 0% no-repeat
  padding-box;
$gradientSilverTransparent: linear-gradient(
    180deg,
    rgba(253, 253, 253, 0.9) 0%,
    rgba(231, 233, 235, 0.9) 100%
  )
  0% 0% no-repeat padding-box;
$borderColor: #eeeeee;

$white: #fdfdfd;
