@import '../../../_vars';

.bet-btn {
  box-shadow: 0px 3px 6px #00000029;
  text-shadow: 0px 3px 6px #00000029;
  border: 2px solid $primaryNightBlue;
  padding: 13px 0;
  border-radius: 6px;
  transition: all 0.3s ease-out;

  &:hover {
    background: $primaryNightBlue;
    color: $white;
  }

  &.selected {
    background: $primaryDarkBlue;
    border-color: $primaryDarkBlue;
    color: $white;
  }

  @media(max-width: 1023px){
    padding: 8px 0;
  }
}
