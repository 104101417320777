@import '../../../_vars';

.transaction-modal {
  .transaction-spinner {
    border-color: $primaryBlue;
    border-right-color: transparent;
  }

  .modal-content {
    border: 0;
    border-radius: 8px;
  }
}
