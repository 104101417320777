@import '../../../../_vars';

.nav-dropdown {
  color: $white;
  text-decoration: none;
  border-radius: 6px;
  border: 2px solid transparent;

  svg {
    fill: $white;
    margin-right: 1rem;
    width: 16px;
    flex-shrink: 0;
  }

  .dropdown-arrow {
    transition: transform 0.2s ease-out;
  }

  &.open {
    .dropdown-arrow {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    border: 2px solid #68cae4;
  }
}
