@import '../../../../_vars';

.nav-option {
  color: $white;
  text-decoration: none;
  border-radius: 6px;
  border: 2px solid transparent;

  svg {
    fill: $white;
    margin-right: 1rem;
    width: 16px;
  }

  &:hover {
    color: $white;
    border: 2px solid #68cae4;
  }

  &.selected {
    font-weight: 600;
    background: $gradientSkyBlue;
    box-shadow: 0px 3px 6px #00000029;
    &:hover {
      border-color: transparent;
    }
  }
}
