@import '.././../_vars';

.withdraw-list {
  max-width: 803px;
  background: $gradientSilver;
  border-radius: 8px;
  height: 494px;
  overflow-x: auto;

  .withdraw-container {
    min-width: 500px;
  }

  .withdraw-games {
    max-height: 429px;
    overflow-y: auto;
  }
}
