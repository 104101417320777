@import '../../../_vars';

.custom-btn {
  background: $gradientSkyBlue;
  max-width: 218px;
  height: 48px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  color: $white;
}
