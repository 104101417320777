@import '../../_vars';

.landing-page {
  .landing-page-background {
    background: url('../../assets/backgrounds/landing.jpg') no-repeat right;
    background-size: cover;
  }

  .sidebar {
    max-width: 381px;
    background: $gradientSilver;

    .logo {
      width: 180px;
      height: auto;
    }

    .connect-btn {
      width: 63%;
      border-radius: 10px;
      border: 2px solid $primaryBlue;
      padding: 12px 0;
      transition: all 0.3s ease-out;

      &:hover {
        border-radius: 30px;
        background: $primaryBlue;
        color: $white;
      }

      &:focus {
        border-radius: 30px;
        background: $primaryDarkBlue;
        border-color: $primaryDarkBlue;
        color: $white;
      }
    }
  }

  @media (max-width: 1023px) {
    align-items: center;

    .landing-page-background {
      width: 100%;
      background-position: center;
    }

    .sidebar {
      border-radius: 12px;
      position: absolute;
      background: $gradientSilverTransparent;
    }
  }
}
