@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.otf') format('otf'),
    url('./assets/fonts/AvenirNextLTPro-It.otf') format('otf'),
    url('./assets/fonts/AvenirNextLTPro-Regular.otf') format('otf');
}

body {
  margin: 0;
  font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
