@import '../../../_vars';

.navigation-wrapper {
  padding-top: 3rem;
  min-height: 90vh;
  border-radius: 8px;
  background: $primaryDarkBlue;
  width: 250px;

  .logo-container {
    max-width: 160px;

    .logo {
      max-width: 108px;
      height: auto;
    }

    .logo-container-line {
      height: 1px;
      clip-path: polygon(
        0 50%,
        20% 20%,
        50% 0,
        80% 20%,
        100% 50%,
        80% 80%,
        50% 100%,
        20% 80%
      );
      background: #7c8390;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .navigation-container {
    max-width: 218px;
  }

  .logout-container {
    bottom: 2rem;
    .logout-btn {
      font-size: 12px;
      color: $neuturalGray;
      svg {
        width: 15px;
        margin-right: 1rem;
        fill: $neuturalGray;
      }
    }
  }
}

.burger-wrapper {
  display: none;
  position: absolute;
  margin-top: 1rem;
  z-index: 11;
}

@media (max-width: 1023px) {
  .burger-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navigation-wrapper {
    padding-top: 1.36rem;
    z-index: 10;
    min-height: 100vh;
    position: absolute !important;
    transform: translateX(-250px);
    transition: all 0.3s ease-out;
    margin: 0 !important;
    
    &.nav-open {
      transform: translateX(0);
    }
  }

  .main-content {
    padding-top: 4rem;
    align-items: flex-start !important;
    min-height: 100vh;
  }
}
