.withdraw-modal {
  .modal-content {
    border: 0;
    border-radius: 8px;

    .withdraw-confirm-btn {
      height: 40px;
      transition: all 0.2s ease-out;

      &:hover {
        transform: scale(1.03);
      }
    }

    .cancel-btn {
      opacity: 0.5;
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}
