.main-layout {
  .main-content {
    overflow: hidden;
    align-items: center;
  }
  
  .navigation-wrapper {
    position: relative;
    margin: 2rem;
    margin-bottom: 0;
  }

  .page-background {
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover !important;
  }
}
