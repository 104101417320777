@import '../../../_vars';

.history-result {
  .arrow-up {
    fill: $primaryGreen;
  }
  .arrow-down {
    fill: $primaryRed;
  }
}
