@import '.././../_vars';

.game-history-list {
  max-width: 1050px;
  background: $gradientSilver;
  border-radius: 8px;
  height: 494px;
  overflow-x: auto;
  
  .game-history-container {
    width: 1002px;
  }
  
  .game-history-games {
    max-height: 429px;
    overflow-y: auto;
  }
}
