.market-data-page {
  .page-background {
    background: url('../../assets/backgrounds/blue-gradient.jpg') no-repeat center;
  }

  #tradingview_container {
    padding-top: 3rem;
    height: 90vh;
    max-width: 1100px;

    iframe {
      border-radius: 12px;
    }
  }
}
