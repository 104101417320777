@import '../../_vars';

.bet-game-wrapper {
  max-width: 643px;
  border-radius: 8px;
  padding: 1.5rem 3rem;

  .bet-game-container {
    max-width: 386px;

    .field {
      border: 1px solid $borderColor;
      border-radius: 6px;
    }

    .bet-choice-container {
      .bet-choice-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 184px;
        height: 48px;
        border-radius: 6px;
        transition: all 0.3s ease-out;

        svg {
          width: 18px;
          height: auto;
        }

        &:hover {
          svg {
            fill: $white;
          }
        }

        &.up-btn {
          border: 1.5px solid $primaryBlue;
          &:hover {
            background: $primaryBlue;
            box-shadow: 0px 3px 6px #024f9c4d; // TODO: Change shadow color
          }
        }

        &.down-btn {
          border: 1.5px solid $primaryRed;
          &:hover {
            background: $primaryRed;
            box-shadow: 0px 3px 6px #9c02044d;
          }
        }

        &.selected {
          &.up-btn {
            background: $primaryBlue;
            box-shadow: 0px 3px 6px #024f9c4d; // TODO: Change shadow color
          }

          &.down-btn {
            background: $primaryRed;
            box-shadow: 0px 3px 6px #9c02044d;
          }
          svg {
            fill: $white;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 1.5rem 0;
    max-width: 500px;

    .bet-game-container {
      max-width: 90%;
      .field {
        border: 1px solid $borderColor;
        border-radius: 6px;
      }

      .bet-choice-container {
        .down-btn-wrapper, .up-btn-wrapper {
          width: 48%;
        }
        .bet-choice-btn {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}
