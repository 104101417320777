@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './_vars';

.fw-500 {
  font-weight: 500;
}

button {
  outline: 0;
  border: 0;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
button {
  margin: 0;
  color: $primaryDarkBlue;
}

.spinner-primary {
  width: 5rem;
  height: 5rem;
  border-width: 0.4rem;
  border-color: $primaryBlue;
  border-right-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
